/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { CloudProvider } from 'src/dto/eob/cloudproviders';
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';

export interface Provider_v1 extends DTO, DTOMethods<Provider_v1> {
    providerType: CloudProvider;
    cloudId?: string;
    cloudName?: string;
    regions: DTORef[];
}
export const Provider_v1: DTOMetaData & DTOClassMethods<Provider_v1> = {
    _label: 'Providers',
    _dto: 'Provider_v1',
    _type: 'provider',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Provider Type', prop: 'providerType', hint: 'Text (max 64k chars)', type: 'enum', fixed: true, required: true, source: undefined, values: ["native", "azure", "gcp", "aws", "other"] },
        { label: 'Cloud ID',   prop: 'cloudId', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Cloud Name', prop: 'cloudName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined }
    ],
    _related: [
        { prop: 'regions', type: 'region', class: 'Region_v1', label: 'Regions' }
    ],
    _endpoint: '/api/eob/v1.0/provider/v1.0',
    _childrenAt: 'regions',
    _docLink: '/wiki/elevate/Assets/provider/',
    _icon: './assets/dtos/provider.svg',

    endpointAPI: new BoundEndpointAPI<Provider_v1>('Provider_v1', DTO),
    from: (obj: any): Provider_v1 => {
        return Object.setPrototypeOf(obj, Provider_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Provider_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
                : Array.isArray(res) ? res.map(a => Provider_v1.from(a) as Provider_v1)
                : Provider_v1.from(res)
        )
    }
}
