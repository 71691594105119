/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';

export interface Networkzone_v1 extends DTO, DTOMethods<Networkzone_v1> {
    tier: string;
    qualifier: string;
    provider: DTORef;
    region: DTORef;
    location: DTORef;
}
export const Networkzone_v1: DTOMetaData & DTOClassMethods<Networkzone_v1> = {
    _label:'Network Zones',
    _dto: 'Networkzone_v1',
    _type: 'networkzone',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Tier', prop: 'tier', hint: 'Text (max 16 chars)', type: 'string', required: true, fixed: true, source: undefined, values: undefined },
        { label: 'Qualifier', prop: 'qualifier', hint: 'Text (max 16 chars)', type: 'string', required: true, fixed: true, source: undefined, values: undefined }     ],
    _related: [
    //    { prop: 'dynatraceEnvironments', type: 'dynatrace-environment', class: 'DynatraceEnvironment_v1', label: 'Dynatrace Environments' }
     ],
    _endpoint: '/api/eob/v1.0/networkzone/v1.0',
    _parentDTONames: 'Location_v1',
    _icon: './assets/dtos/zone.svg',
    _docLink: '/wiki/elevate/Assets/networkzone/',

    endpointAPI: new BoundEndpointAPI<Networkzone_v1>('Networkzone_v1', DTO),
    from: (obj: any): Networkzone_v1 => {
        return Object.setPrototypeOf(obj, Networkzone_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Networkzone_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Networkzone_v1.from(a) as Networkzone_v1)
            : Networkzone_v1.from(res)
    )}
}
