/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI} from '../dto';

export interface Location_v1 extends DTO, DTOMethods<Location_v1> {
    sitecode: string; // DC code/Location Code
    provider: DTORef;
    region: DTORef;
    address01: string;
    address02: string;
    city: string;
    state: string;
    zip: string;
    longitude: string;
    latitude: string;
    zones: DTORef[];
}
export const Location_v1: DTOMetaData & DTOClassMethods<Location_v1> = {
    _label:'Locations',
    _dto: 'Location_v1',
    _type: 'location',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this location', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Location Code', prop: 'sitecode', hint: 'Text (max 64k chars)', type: 'string', required: true, fixed: true, source: undefined, values: undefined },
        { label: 'Address01', prop: 'address01', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Address02', prop: 'address02', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'City', prop: 'city', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'State', prop: 'state', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Zip', prop: 'zip', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Longitude', prop: 'longitude', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Latitude', prop: 'latitude', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
     ],
    _related: [
        { prop: 'zones', type: 'networkzone', class: 'Networkzone_v1', label: 'Network Zones' },
     ],
    _endpoint: '/api/eob/v1.0/location/v1.0',
    _childrenAt: 'zones',
    _parentDTONames: 'Region_v1',
    _icon: './assets/dtos/location.svg',
    _docLink: '/wiki/elevate/Assets/location/',
    endpointAPI: new BoundEndpointAPI<Location_v1>('Location_v1', DTO),
    from: (obj: any): Location_v1 => {
        return Object.setPrototypeOf(obj, Location_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Location_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Location_v1.from(a) as Location_v1)
            : Location_v1.from(res)
    )}
}
