/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';

export interface Region_v1 extends DTO, DTOMethods<Region_v1> {
    country: string;
    area: string;
    locations: DTORef[];
}
export const Region_v1: DTOMetaData & DTOClassMethods<Region_v1> = {
    _label:'Regions',
    _dto: 'Region_v1',
    _type: 'region',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this region', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Country', prop: 'country', hint: 'Text (max 64k chars)', type: 'string', required: true, fixed: true, source: undefined, values: undefined },
        { label: 'Area', prop: 'area', hint: 'Text (max 64k chars)', type: 'string', required: true, fixed: true, source: undefined, values: undefined },
     ],
    _related: [
        { prop: 'locations', type: 'location', class: 'Location_v1', label: 'Locations' },
     ],
    _endpoint: '/api/eob/v1.0/region/v1.0',
    _docLink: '/wiki/elevate/Assets/region/',

    _childrenAt: 'locations',
    _parentDTONames: 'Provider_v1',
    _icon: './assets/dtos/region.svg',

    endpointAPI: new BoundEndpointAPI<Region_v1>('Region_v1', DTO),
    from: (obj: any): Region_v1 => {
        return Object.setPrototypeOf(obj, Region_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Region_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Region_v1.from(a) as Region_v1)
            : Region_v1.from(res)
    )}
}
