/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface Costcenter_v1 extends DTO, DTOMethods<Costcenter_v1> {
    defaultRateGroup: DTORef;
    organizations: DTORef[];
    resolvedUsers: Attachment[];
}
export const Costcenter_v1: DTOMetaData & DTOClassMethods<Costcenter_v1> = {
    _label:'Cost Centers',
    _dto: 'Costcenter_v1',
    _type: 'costcenter',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this costcenter', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Default rate group', prop: 'defaultRateGroup', required: true, hint: 'Rategroup', type: 'dto', fixed: true, source: '/api/eob/v1.0/rategroup/v1.0', values: undefined },
     ],
    _related: [
        { prop: 'organizations', type: 'organiziation', class: 'Organization_v1', label: 'Organizations' },
   //     { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api/eob/v1.0/costcenter/v1.0',
    _docLink: '/wiki/elevate/Assets/costcenter/',
    _icon: './assets/dtos/costcenter.svg',

    endpointAPI: new BoundEndpointAPI<Costcenter_v1>('Costcenter_v1', DTO),
    from: (obj: any): Costcenter_v1 => {
        return Object.setPrototypeOf(obj, Costcenter_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Costcenter_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Costcenter_v1.from(a) as Costcenter_v1)
            : Costcenter_v1.from(res)
    )}
}
